@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');


*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

h1 {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    color: rgb(244, 139, 70);
    font-family: 'Orbitron', sans-serif;
}